import React, { Component, ReactNode } from 'react';
import { Routes, Route } from 'react-router';
import { Api, EventBus } from 'src/helpers/new';
import { Enrollment, Lessons, PreExam, Exam, PostExam, ExamSummary } from './Stages';
import Proctoring from './Proctoring';
import CourseManager from './CourseManager';
import ExamStartModal from 'src/components/ExamStartModal/ExamStartModal';
import { Spinner } from 'src/components/Spinner';
import NmlsUserIdModal from './Modals/NmlsUserIdModal';
import { IUserLoggedIn } from 'src/layouts/Main/UserBar/Menus/ProfilePopOver';
import PassProctoring from './Stages/ProctoringPass/PassProctoring';
import { isNil } from 'lodash';
import ClarityScriptHelper from 'src/helpers/ClarityScriptHelper';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';
import * as Sentry from '@sentry/react';

interface IRouteProps {
    params: {
        courseId: string;
        chapterId?: string;
        lessonId?: string;
        courseSection?: 'enrollment' | 'chapters' | 'preexam' | 'exam' | 'postexam' | 'exam-summary';
    };

    location: any;
    navigate: any;
}

export interface IProps extends IRouteProps {
    loggedIn: IUserLoggedIn;
    setLoggedIn: (payload: any) => void;
}

interface IState {
    course?: any;
    visible?: boolean;
    verifiedStudent?: boolean;
    isSuspentionDataLoaded: boolean;
    suspentionData: {
        courseSuspendedAt: null | Date;
        packageSuspendedAt: null | Date;
        userSuspendedAt: null | Date;
    };
    showNmlsUserIdPrompt: boolean;
    internetStatus: string;
}

class Course extends Component<IProps, IState> {
    state: IState = {
        visible: false,
        verifiedStudent: false,
        isSuspentionDataLoaded: false,
        suspentionData: {
            courseSuspendedAt: null,
            packageSuspendedAt: null,
            userSuspendedAt: null,
        },
        showNmlsUserIdPrompt: false,
        internetStatus: '',
    };

    shouldCallLeaveSockets = true;
    modalConfirmBtnRef: React.RefObject<HTMLButtonElement> = React.createRef();
    interenetConnectionLostBtnRef: React.RefObject<HTMLButtonElement> = React.createRef();

    async componentDidMount() {
        await this.fetchCourseSuspendedData();
        EventBus.on('enter-pre-exam', this.enterPreExam as any);
        EventBus.on('enter-post-exam', this.enterPostExam as any);
        EventBus.on('enter-exam-screen', this.enterExamScreen as any);

        window.socket.emit('enter-course', {
            courseId: this.props.params.courseId,
        });
        window.socket.on('usersocket-changed-alert', this.notifyOtherCourseSessionDisconnected);
        // On Old Course Open Tab We do not need to call leave-user-course and leave-user-lesson
        window.socket.on('usersocket-disconnected', ({ userCourseId }: { userCourseId: string }) => {
            const courseId = this.props.params.courseId;
            this.shouldCallLeaveSockets = false;
            if (courseId == userCourseId.toString()) {
                this.modalConfirmBtnRef?.current?.click();
                this.props.navigate(`/`, {
                    state: {
                        avoidRouteLeavingGuard: true,
                    },
                });
            }
        });
        window.socket.on('disconnect', this.handleOffline);
        window.addEventListener('online', this.handleOnline);
    }

    componentWillUnmount() {
        if (this.shouldCallLeaveSockets) {
            window.socket.emit('leave-course', {
                courseId: this.props.params.courseId,
            });
        }
        window.socket.off('usersocket-changed-alert');
        window.socket.off('usersocket-disconnected');
        window.socket.off('disconnect');
        EventBus.remove('enter-pre-exam', this.enterPreExam as any);
        EventBus.remove('enter-post-exam', this.enterPostExam as any);
        EventBus.remove('enter-exam-screen', this.enterExamScreen as any);
        window.removeEventListener('online', this.handleOnline);
        EventBus.dispatch('show-exam-banner', { isResume: null });
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            await this.fetchCourseSuspendedData();
        }
    }

    reloadScreen = () => {
        const isInExam = /\/exam$/.test(this.props.location.pathname);
        if (isInExam) {
            EventBus.dispatch('exit-exam');
        } else {
            window.location.reload();
        }
    };

    handleOnline = () => {
        this.setState({ internetStatus: 'online' });
        if (this.interenetConnectionLostBtnRef && this.interenetConnectionLostBtnRef?.current) {
            this.interenetConnectionLostBtnRef?.current?.click();
        } else {
            this.reloadScreen();
        }
    };

    handleOffline = () => {
        this.setState((prevState) => ({
            ...prevState,
            internetStatus: 'offline',
        }));

        EventBus.dispatch('confirmation-popup', {
            className: 'course-modals',
            title: '',
            body: 'You were offline for sometime. Please click the Refresh button to continue where you were left off.',
            confirm: {
                text: 'Refresh',
                action: this.reloadScreen,
                ref: this.interenetConnectionLostBtnRef,
            },
            cancel: null,
            dismiss: () => {},
            backdrop: false,
        });
    };

    notifyOtherCourseSessionDisconnected = ({ userCourseId }: { userCourseId: string }) => {
        const { courseId } = this.props.params;
        if (userCourseId === courseId) {
            EventBus.dispatch('confirmation-popup', {
                className: 'course-modals',
                title: '',
                body: 'You are logged in on another device as well. Your session on the other device will be paused. Please click “Continue” to continue studying on the current device.',
                confirm: {
                    text: 'Continue',
                    action: () => {},
                    ref: this.modalConfirmBtnRef,
                },
                cancel: null,
                dismiss: () => {},
                backdrop: false,
            });
        }
    };

    fetchCourseSuspendedData = async () => {
        const { courseId } = this.props.params;
        const { success, response } = await Api.call('GET', `/users/courses/${courseId}/suspentionDetails`);

        if (success) {
            this.setState({
                isSuspentionDataLoaded: true,
                suspentionData: response,
            });
        }
    };

    closeModal = () => {
        this.setState({ visible: false });
    };

    submitModal = async (val: any) => {
        const { courseId } = this.props.params;
        const { success } = await Api.call('POST', `/users/pre-exam/verify/${courseId}`, {
            verificationCode: val,
        });
        this.setState({ verifiedStudent: success });
        if (success) {
            this.setState({ visible: false });
            this.enterExamScreen();
        }
    };

    enterExamScreen = async () => {
        try {
            const { courseId } = this.props.params;
            const { response } = await Api.call('get', `/users/courses/${courseId}`);
            if (response) {
                EventBus.dispatch('display-chat-icon', { enable: false, course: response });
            }
            if (
                this.state.verifiedStudent ||
                response.proctoringSettings?.verificationCode?.length === 0 ||
                !response.proctoringSettings?.verificationCode
            ) {
                this.setState({ visible: false, verifiedStudent: false });
                this.props.navigate(`/courses/${courseId}/exam`, { replace: true });
            } else {
                this.setState({ visible: true });
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    enterPreExam = async () => {
        try {
            const { courseId } = this.props.params;
            const { response } = await Api.call('get', `/users/courses/${courseId}`);
            if (response) {
                EventBus.dispatch('display-chat-icon', { enable: false, course: response });
                if (
                    !this.getUserCompletedPostExam(response) &&
                    this.getIsPostExamRedirection(response) &&
                    (response.status === 'EXAM_PASSED' || response.status === 'FAILED')
                ) {
                    this.props.navigate(`/courses/${courseId}/postexam`);
                } else if (!this.getUserCompletedPreExam(response)) {
                    this.props.navigate(`/courses/${courseId}/preexam`);
                } else if (this.getUserProctorPass(response)) {
                    this.props.navigate(`/courses/${courseId}/proctoring-pass`, { replace: true });
                } else {
                    this.props.navigate(`/courses/${courseId}/exam-summary`);
                }
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    enterPostExam = async () => {
        try {
            const { courseId } = this.props.params;
            const { response } = await Api.call('get', `/users/courses/${courseId}`);

            if (response) {
                if (!this.getUserCompletedPostExam(response) && this.getIsPostExamRedirection(response)) {
                    this.props.navigate(`/courses/${courseId}/postexam`, { replace: true });
                } else if (this.getUserProctorPass(response)) {
                    this.props.navigate(`/courses/${courseId}/proctoring-pass`, { replace: true });
                } else {
                    this.props.navigate(`/courses/${courseId}/exam-summary`, { replace: true });
                }
                EventBus.dispatch('display-chat-icon', { enable: false, course: response });
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    handleStageRedirect = () => {
        const { course } = this.state;
        const { navigate } = this.props;
        const baseUrl = '/courses';

        const {
            userNotEnrolled,
            courseInProgress,
            userCompletedPreExam,
            userInExam,
            userCompletedPostExam,
            courseProgressAfterExam,
            userProctorPass,
        } = this.userCourseStatuses;

        if (userNotEnrolled) {
            navigate(`${baseUrl}/${course._id}/enrollment`, { replace: true });
        } else if (courseInProgress || course.courseType === 'optional' || courseProgressAfterExam) {
            navigate(`${baseUrl}/${course._id}/chapters/${course.lastChapterId}/lessons/${course.lastLessonId}`, {
                replace: true,
            });
        } else if (!userCompletedPreExam) {
            navigate(`${baseUrl}/${course._id}/preexam`, { replace: true });
        } else if (userProctorPass) {
            navigate(`${baseUrl}/${course._id}/proctoring-pass`, { replace: true });
        } else if (userInExam) {
            navigate(`${baseUrl}/${course._id}/exam-summary`, {
                replace: true,
                state: this.props.location.state,
            });
        } else if (!userCompletedPostExam) {
            navigate(`${baseUrl}/${course._id}/postexam`, { replace: true });
        }
        EventBus.dispatch('display-chat-icon', { enable: true, course: course });
    };

    get userCourseStatuses(): {
        userInExam: boolean;
        userCompletedPostExam: boolean;
        userNotEnrolled: boolean;
        courseInProgress: boolean;
        userCompletedPreExam: boolean;
        courseProgressAfterExam: boolean;
        userProctorPass: boolean;
    } {
        const { course } = this.state;
        const { params, location } = this.props;

        const userNotEnrolled = !course.enrollmentUpdatedAt;
        const courseInProgress =
            (((course.status === 'NEW' && !params.lessonId) || (course.status === 'NEW' && !params.chapterId)) &&
                location.state?.isProctorPass !== true &&
                !params.courseSection) ||
            params.courseSection === 'chapters' ||
            course.percentageProgress < 100;

        const userCompletedPreExam = this.getUserCompletedPreExam(course);
        const userCompletedPostExam = this.getUserCompletedPostExam(course);
        const isPostExamRedirection = this.getIsPostExamRedirection(course);

        const userInExam =
            (course.status === 'IN_EXAM' && params.courseSection !== 'exam' && params.courseSection !== 'postexam') ||
            course.status === 'NEW' ||
            ((course.status === 'EXAM_PASSED' || course.status === 'FAILED') &&
                userCompletedPostExam &&
                params?.courseSection !== 'exam-summary') ||
            (params?.courseSection === 'exam' && !userCompletedPostExam) ||
            !isPostExamRedirection;

        const courseProgressAfterExam =
            (course.status === 'EXAM_PASSED' || course.status === 'FAILED') &&
            userCompletedPostExam &&
            params?.courseSection !== 'exam-summary' &&
            !isNil(params?.courseSection);

        const userProctorPass = this.getUserProctorPass(course);
        return {
            userNotEnrolled,
            courseInProgress,
            userCompletedPreExam,
            userInExam,
            userCompletedPostExam,
            courseProgressAfterExam,
            userProctorPass,
        };
    }

    getIsPostExamRedirection = (course: any) => {
        if (course.postExamSetting?.isExamPassRequired === true) {
            return course.status === 'EXAM_PASSED' ? true : false;
        } else {
            return true;
        }
    };

    getUserCompletedPreExam = (course: any) => {
        return !isNil(course.preExamUpdatedAt) && !isNil(course.preExamForm) && isNil(course.isPreExamFormSaved);
    };

    getUserCompletedPostExam = (course: any) => {
        return course.examAttempts > 0 && !isNil(course.postExamUpdatedAt) && !isNil(course.postExamForm);
    };

    getUserProctorPass = (course: any) => {
        const {
            proctoringSettings: { bioSight },
        } = course;
        const isbioSight = bioSight === 'biosight';

        return !course.isProctorPass && course.proctorPass?.content && course.proctorPass?.price > 0 && isbioSight;
    };

    handleCourseLoad = (course: any) => {
        if (course?.proctoringSettings?.bioSight === 'biosig-nmls' && !this.props.loggedIn?.user?.nmlsUserId) {
            this.setState({
                showNmlsUserIdPrompt: true,
                course: course,
            });
        } else {
            this.setState({ course }, () => {
                this.handleStageRedirect();
            });
        }
    };

    handleNmlsUserIdSubmit = async (value: string) => {
        const { success } = await Api.call('PUT', '/users/profile', {
            nmlsUserId: value,
        });

        if (success) {
            this.props.setLoggedIn({
                ...this.props.loggedIn,
                user: {
                    ...this.props.loggedIn.user,
                    nmlsUserId: value,
                },
            });

            this.setState({ showNmlsUserIdPrompt: false });
            this.handleStageRedirect();
        }
    };

    redirectToExamSummary = () => {
        const { courseId } = this.props.params;
        this.props.navigate(`/courses/${courseId}/exam-summary`);
    };

    public render(): ReactNode {
        const suspentionDetails = this.state.suspentionData;

        if (!this.state.isSuspentionDataLoaded) {
            return <Spinner />;
        }

        if (
            suspentionDetails.courseSuspendedAt ||
            suspentionDetails.packageSuspendedAt ||
            suspentionDetails.userSuspendedAt
        ) {
            EventBus.dispatch('action-confirmation-popup', {
                title: 'Suspended',
                body: 'This course has been suspended. If you believe this is an error, please contact support@realestateu.com.',
                doAsyncInBackground: false,
                cancel: {
                    text: 'OK',
                    action: () => {
                        this.props.navigate('/');
                    },
                },
            });

            return <></>;
        }

        return (
            <CourseManager courseId={this.props.params.courseId} onCourseLoad={this.handleCourseLoad}>
                <ClarityScriptHelper />
                <NmlsUserIdModal
                    showNmlsUserIdPrompt={this.state.showNmlsUserIdPrompt}
                    submitModal={this.handleNmlsUserIdSubmit}
                />
                <Routes>
                    <Route path='enrollment' element={<Enrollment />} />
                    <Route path='preexam' element={<PreExam />} />
                    <Route path='exam' element={<Exam />} />
                    <Route path='exam-summary' element={<ExamSummary />} />
                    <Route path='proctoring-pass' element={<PassProctoring />} />
                    <Route path='postexam' element={<PostExam />} />
                    <Route path=':courseParam?/:chapterId/lessons/:lessonId/cards/:cardIndex' element={<Lessons />} />
                    <Route path=':courseParam?/:chapterId/lessons/:lessonId' element={<Lessons />} />
                    <Route path=':courseParam?/:chapterId' element={<Lessons />} />
                </Routes>
                <Proctoring redirectToExamSummary={this.redirectToExamSummary} />
                <ExamStartModal
                    visible={this.state.visible}
                    closeModal={this.closeModal}
                    submitModal={this.submitModal}
                />
            </CourseManager>
        );
    }
}

export default withRouterAndRedux(
    Course,
    (state: any) => {
        return { loggedIn: state.loggedIn };
    },
    {
        setLoggedIn: (payload: any) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
    },
);
