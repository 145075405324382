import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { Api, EventBus } from 'src/helpers/new';
import validator from 'validator';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import { Spinner } from 'react-bootstrap';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            previousPath: '/',
            invalidField: '',
            setEmailError: '',
            setPasswordError: '',
            isMaintenanceLoading: true,
            isShowMaintenancePrompt: false,
            isLoading: false,
        };
    }

    async componentDidMount() {
        if (this.props?.location?.state?.previousPath) {
            this.setState({
                previousPath: this.props.location.state.previousPath,
            });
        }

        const { success, response } = await Api.call('GET', '/settings/maintenance');
        if (success) {
            if (!isEmpty(response)) {
                if (response.startTime && response.endTime) {
                    if (
                        DateTimeFormatHelper.isBetween(
                            DateTimeFormatHelper.currentDate(),
                            DateTimeFormatHelper.getDate(response.startTime),
                            DateTimeFormatHelper.getDate(response.endTime),
                            null,
                            '[]',
                        )
                    ) {
                        this.props.setLoggedIn({
                            maintenance: response,
                        });
                        this.setState({ isShowMaintenancePrompt: true });
                        EventBus.dispatch('show-maintenance-screen-prompt', true);
                    }
                }
            }
            this.setState({ isMaintenanceLoading: false });
        }
    }

    componentWillUnmount() {
        this.setState({ isShowMaintenancePrompt: false, isMaintenanceLoading: false });
    }

    handleFormSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            error: '',
            invalidField: '',
            setEmailError: '',
            setPasswordError: '',
            isLoading: true,
        });
        const { email, password } = this.state;
        if (!validator.isEmail(email)) {
            this.setState({ setEmailError: 'Enter valid e-mail address', isLoading: false });
        }
        if (!password) {
            this.setState({ setPasswordError: 'Enter valid Password', isLoading: false });
        }

        if (validator.isEmail(email) && password) {
            this.login(this.state.email, this.state.password);
        }
    };

    login = async (email, password) => {
        try {
            const storedLoginCheckoutData = sessionStorage.getItem('login-checkout-data');
            const loginCheckoutData = JSON.parse(storedLoginCheckoutData);

            const { response, message, success } = await Api.call(
                'POST',
                '/users/login',
                {
                    email,
                    password,
                    browserAgent: navigator.userAgent,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    ...loginCheckoutData,
                },
                false,
                false,
            );

            if (success) {
                localStorage.setItem('user', JSON.stringify(response.user));
                localStorage.setItem('authToken', response.token);

                const isUserAdmin = response.user.adminLevel === 1;
                const isAdminPreviousPathExists = this.state.previousPath?.includes('admin');

                let updatedPath = this.state.previousPath;
                if (!isUserAdmin && isAdminPreviousPathExists) {
                    updatedPath = '/';
                }

                if (!isEmpty(loginCheckoutData?.packages) || !isEmpty(loginCheckoutData?.bundles)) {
                    updatedPath = `/checkout`;
                }

                this.setState({ previousPath: updatedPath, error: null }, () => {
                    this.props.setLoggedIn(response);
                    if (!isEmpty(loginCheckoutData)) {
                        sessionStorage.removeItem('login-checkout-data');
                    }
                    this.props.navigate(updatedPath, { replace: true });
                });

                window.socket = io(process.env.REACT_APP_WS_URL, {
                    query: { token: response.token },
                    transports: ['websocket'],
                });

                window.socket.on('connect', () => {
                    window.socket.on('maintenance-settings-updated', this.maintenanceEvent);
                    EventBus.dispatch('add-notification-socket-event');
                    window.socket.emit('get-notification-count', response.user._id);
                });

                if (!isEmpty(response?.user?.maintenance)) {
                    const maintenance = response.user.maintenance;
                    if (
                        maintenance.startTime &&
                        maintenance.endTime &&
                        DateTimeFormatHelper.isBetween(
                            DateTimeFormatHelper.currentDate(),
                            DateTimeFormatHelper.getDate(maintenance.startTime),
                            DateTimeFormatHelper.getDate(maintenance.endTime),
                            null,
                            '[]',
                        )
                    ) {
                        EventBus.dispatch('show-maintenance-screen-prompt', true);
                    }
                }
            } else {
                this.setState({
                    error: message,
                    invalidField: response.field,
                    isLoading: false,
                    password: response.field === 'password' ? '' : this.state.password,
                });
            }
        } catch (error) {
            this.setState({ error: 'An unexpected error occurred. Please try again.' });
        }
    };

    maintenanceEvent = async (data) => {
        if (!isEmpty(data)) {
            if (data?.maintenance?.startTime && data?.maintenance?.endTime) {
                if (
                    DateTimeFormatHelper.isBetween(
                        DateTimeFormatHelper.currentDate(),
                        DateTimeFormatHelper.getDate(data?.maintenance?.startTime),
                        DateTimeFormatHelper.getDate(data?.maintenance?.endTime),
                        null,
                        '[]',
                    )
                ) {
                    window.location.reload(true);
                }
            }
        }
    };

    handleInputChange = (e) => {
        this.setState({
            error: '',
            invalidField: '',
            setEmailError: '',
            setPasswordError: '',
        });
        const input = e.target;

        this.setState({
            [input.name]: input.value,
        });
    };

    render() {
        if (this.state.isMaintenanceLoading || this.state.isShowMaintenancePrompt) {
            return (
                <div className='center-loading big'>
                    <Spinner animation='border' />
                </div>
            );
        }
        if (this.props.loggedIn.token) {
            return <Navigate to={this.state.previousPath} />;
        }

        const { error, invalidField, setEmailError, setPasswordError, isLoading, email, password } = this.state;

        return (
            <div className='centered-block login_form'>
                <div className={'form form--narrow form--floating' + (error ? ' form--with-error' : '')}>
                    {!invalidField && error && <div className='form__error'>{error}</div>}
                    <div className='form__content'>
                        <h2>Login</h2>
                        <form action='/' onSubmit={this.handleFormSubmit}>
                            <div className='field__wrapper'>
                                <div className='form__field'>
                                    <label htmlFor='email'>Email</label>
                                    <input
                                        type='text'
                                        name={'email'}
                                        onChange={this.handleInputChange}
                                        value={email}
                                        className={invalidField === 'email' ? 'field__error' : ''}
                                    />
                                </div>
                                <span className='alert_icon'>
                                    {invalidField === 'email' && <Fa icon={faExclamationTriangle} />}
                                </span>
                            </div>
                            {setEmailError && <div className='login-form__error'>{setEmailError}</div>}
                            {invalidField === 'email' && <div className='login-form__error'>{error}</div>}
                            <div className='field__wrapper'>
                                <div className='form__field'>
                                    <label htmlFor='password'>Password</label>
                                    <input
                                        type='password'
                                        name={'password'}
                                        value={password}
                                        onChange={this.handleInputChange}
                                        className={invalidField === 'password' ? 'field__error' : ''}
                                    />
                                </div>
                                <span className='alert_icon'>
                                    {invalidField === 'password' && <Fa icon={faExclamationTriangle} />}
                                </span>
                            </div>
                            {setPasswordError && <div className='login-form__error'>{setPasswordError}</div>}
                            {invalidField === 'password' && error && <div className='login-form__error'>{error}</div>}
                            <div className='form__message'>
                                By logging in, you are agreeing to the following&nbsp;
                                <Link to='/terms_of_service'>Terms of Service</Link>
                                &nbsp;and our&nbsp;
                                <Link to='/privacy_policy'>Privacy Policy</Link>.
                            </div>
                            <div className='form__buttons'>
                                <button type='submit' className='btn bp' disabled={isLoading}>
                                    Login {isLoading && <Spinner animation='border' size='sm' />}
                                </button>
                                <Link to='/reset-password' className='btn btn--link'>
                                    Forgot your password?
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapState = (state) => {
    return { loggedIn: state.loggedIn };
};

export default withRouterAndRedux(Login, mapState, {
    setLoggedIn: (payload) => ({
        type: 'SET_LOGGED_IN',
        payload,
    }),
});
