import React from 'react';
import { CartData } from './CheckoutTypeHelper';
import { IState } from './Checkout';

interface CheckoutContextType extends IState {
    updateCart: (
        cartData?: CartData,
        onError?: (error: any) => void,
        onSuccess?: (response: any) => void,
    ) => Promise<void>;
    updateTopSuggestion: () => unknown;
    switchTab: (arg0: number, arg1: boolean) => unknown;
    updateCheckoutState: (state: any) => void;
    getPrice: () => Promise<{ success: boolean; response: any }>;
    getOrderDetails: () => {
        couponDiscount: number;
        promoDiscount: number;
        subTotal: number;
        totalPrice: number;
    };
}
export default React.createContext<CheckoutContextType>({
    status: 'LOADING',
    billingAddress: {
        state: 'none',
        country: 'US',
        streetLines: [''],
        zipCode: '',
    },
    contact: {
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
    },
    user: {},
    currentTab: 0,
    tabs: [
        { title: 'Your Order', completed: true },
        { title: 'Contact Info', completed: false },
        { title: 'Billing Info', completed: false },
        { title: 'Payment', completed: false },
    ],
    upsellSuggestions: [],
    topSuggested: {},
    isDisplaySuggestion: false,
    isPriceUpdate: false,
    termsAndCondition: '',
    showTermsAndCondition: false,
    packages: [],
    upsells: [],
    state: '',
    title: '',
    isAdminPreview: false,
    couponCode: null,
    paymentPackages: [],
    price: 0,
    tax: 0,
    isSingleCheckout: false,
    validation: {
        emailMessage: '',
        zipcodeMessage: '',
        inVaild: false,
    },
    surveyConfig: {
        discountCode: '',
        discountHeading: '',
        discountText: '',
        surveyTriggerDuration: 2,
        thoughtMetricSurvey: false,
    },
    previousX: null,
    previousY: null,
    isSurveyModalShown: false,
    isSurveyModalAction: false,
    updateCart: () => {
        return new Promise((resolve, reject) => {});
    },
    updateTopSuggestion: () => {},
    switchTab: (arg0: number, arg1: boolean) => {},
    updateCheckoutState: (state: any) => {},
    getPrice: async () => {
        return {
            success: false,
            response: {
                tax: 0,
            },
        };
    },
    getOrderDetails: () => {
        return { couponDiscount: 0, promoDiscount: 0, subTotal: 0, totalPrice: 0 };
    },
});
